<template>
  <div class="checkbox__wrapper">
    <div :class="[containerStyle, 'checkbox__container']">
      <!-- @slot Custom content -->
      <slot v-bind="{ isChecked }"></slot>

      <div :class="[inputWrapperStyle, 'checkbox__input-wrapper']">
        <input
          v-bind="$attrs"
          v-on="{
            ...$listeners,
            input: handleInput,
            invalid: checkValidity,
            mousedown: $event => $event.preventDefault(),
          }"
          :value="value"
          :checked="isChecked || $attrs.checked"
          :id="id"
          class="checkbox__input"
          type="checkbox"
          ref="input"
        />
      </div>

      <label
        v-if="label"
        :for="id"
        :class="[checkboxLabelStyle, 'checkbox__label']">
        <!-- @slot Custom label -->
        <slot name="label">
          {{ label }}
        </slot>
      </label>
    </div>

    <!-- @slot Custom error  -->
    <slot name="error">
      <span
        v-if="errorsAggregate.length"
        :title="errorsAggregate[0].message"
        :class="[
          'checkbox__error-message',
          { [`checkbox__error-message--${errorsAggregate[0].type || 'error'}`]: true },
        ]">
        {{ errorsAggregate[0].message }}
      </span>
    </slot>
  </div>
</template>

<script>

import { handleValidityCheck }      from '../../../utils/DOMUtils.js';


export default {
  name: 'a-Checkbox',
  inheritAttrs: false,
  model: {
    prop: 'parentModel',
  },
  props: {
    parentModel: null,
    value: {
      type: [String, Number],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isLabelSm: {
      type: Boolean,
      default: false,
    },
    hasTextOverflow: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    localErrors: [],
  }),
  computed: {
    isChecked() {
      return Array.isArray(this.parentModel)
        ? this.parentModel.includes(this.value)
        : this.parentModel === true;
    },
    containerStyle() {
      return {
        'checkbox__container--overflow': this.hasTextOverflow,
        'checkbox__container--disabled': Boolean(this.$attrs.disabled),
      };
    },
    inputWrapperStyle() {
      return { 'checkbox__input-wrapper--checked': this.isChecked };
    },
    checkboxLabelStyle() {
      return { 'checkbox__label--sm': this.isLabelSm };
    },
    errorsAggregate() {
      return [
        ...this.localErrors,
        ...this.errors,
      ];
    },
  },
  watch: {
    parentModel() {
      this.checkValidity();
    },
  },
  methods: {
    handleValidityCheck,
    handleInput({ target }) {
      /**
       * Emits the checked state as an updated array if the parent 'v-model' is bound to an array
       * Emits a boolean otherwise
       *
       * @event input
       * @type {array | boolean}
       */
      const isChecked = target.checked;
      const canCheckMultipleValues = Array.isArray(this.parentModel);

      if (!canCheckMultipleValues) this.$emit('input', isChecked);
      else {
        const newParentModel = [...this.parentModel];

        isChecked
          ? newParentModel.push(this.value)
          : newParentModel.splice(newParentModel.indexOf(this.value), 1);

        this.$emit('input', newParentModel);
      }
    },
    checkValidity() {
      const errors = this.handleValidityCheck(this.$refs.input);

      this.localErrors = errors.length ? [ ...errors, ...this.localErrors ] : [];
    },
  },
};
</script>

<style lang="scss">

.checkbox {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
    align-items: center;
    align-items: flex-start;
    cursor: pointer;
    max-width: 100%;
    min-width: 0;
    font-family: var(--font-stack-secondary);

    * {
      cursor: pointer;
    }

    &--overflow {
      max-width: 100%;
    }

    &--disabled {
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
      .checkbox__input-wrapper::before {
        border-color: var(--color-grey-neutral);
      }
      .checkbox__input-wrapper::after {
        background-color: var(--color-grey-neutral);
      }
      .checkbox__label {
        color: var(--color-grey-neutral);
      }
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      height: 20px;
      width: 20px;
      background-color: var(--color-white);
      border: 1px solid var(--color-black);
      border-radius: 2px;
      pointer-events: none;
    }

    &--checked {
      &::after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        width: 10px;
        transform: translate(50%, 50%);
        border-radius: 2px;
        background-color: var(--color-black);
        pointer-events: none;
      }
    }
  }

  &__input {
    position: relative;
    height: 20px;
    width: 20px;
  }

  &__label {
    position: relative;
    top: -4px;
    flex: 1 1 auto;
    margin-left: var(--space-sm);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
    font-weight: var(--font-base);
    line-height: var(--lineheight-md);
  }

  &__error-message {
    margin-top: var(--space-xxs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xxs);
    font-weight: 600;
    line-height: var(--lineheight-xs);

    $levels: "valid", "warning", "alert";

    @each $level in $levels {
      &--#{$level} {
        color: var(--color-#{$level});
      }
    }
  }
}

</style>
