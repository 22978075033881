var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkbox__wrapper"},[_c('div',{class:[_vm.containerStyle, 'checkbox__container']},[_vm._t("default",null,null,{ isChecked: _vm.isChecked }),_c('div',{class:[_vm.inputWrapperStyle, 'checkbox__input-wrapper']},[_c('input',_vm._g(_vm._b({ref:"input",staticClass:"checkbox__input",attrs:{"id":_vm.id,"type":"checkbox"},domProps:{"value":_vm.value,"checked":_vm.isChecked || _vm.$attrs.checked}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
          {input: _vm.handleInput,
          invalid: _vm.checkValidity,
          mousedown: function ($event) { return $event.preventDefault(); }})))]),(_vm.label)?_c('label',{class:[_vm.checkboxLabelStyle, 'checkbox__label'],attrs:{"for":_vm.id}},[_vm._t("label",[_vm._v(" "+_vm._s(_vm.label)+" ")])],2):_vm._e()],2),_vm._t("error",[(_vm.errorsAggregate.length)?_c('span',{class:[
        'checkbox__error-message',
        ( _obj = {}, _obj[("checkbox__error-message--" + (_vm.errorsAggregate[0].type || 'error'))] = true, _obj ) ],attrs:{"title":_vm.errorsAggregate[0].message}},[_vm._v(" "+_vm._s(_vm.errorsAggregate[0].message)+" ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }